import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { useLocation, Redirect } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuth } from '../../../global/Authentication/AuthProvider';
import { Helmet } from '../../../shared';
import tags from '../../../../apis/seoTags';
import CenteredGrid from '../../../shared/CenteredGrid';
import { Img } from '../../../global/ImgUtils';
import styles from './Thanks.styles';
import Slideshow from './Slideshow';
import Slide from './Slide';

const useServiceCardStyles = makeStyles({
  card: {
    width: 248,
    marginBottom: 18,
    textAlign: 'center'
  },
  title: {
    fontFamily: 'Gilroy-Bold',
    color: '#0b1829',
    marginTop: 48,
    whiteSpace: 'pre-line'
  },
  subtitle: {
    fontFamily: 'Gilroy-Regular',
    color: '#787b80',
    marginTop: 16,
    lineHeight: 1.63
  }
});

const ServiceCard = ({ title, description, image }) => {
  const classes = useServiceCardStyles();

  return (
    <Grid key={title} container direction="column" alignItems="center" className={classes.card}>
      <Img src={image} alt={title} width={144} height={144} />
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {description}
      </Typography>
    </Grid>
  );
};

export const cardsContent = [
  {
    title: 'White glove\nservice',
    description: 'Dedicated success and sales teams to tailor Swag orders to meet your project needs',
    image: '/images/catalog/confirmation/white-glove-service.png'
  },
  {
    title: 'Project\nmanagement',
    description: 'Manage your Swag project from item selection through design mockups to their doorstep',
    image: '/images/catalog/confirmation/project-management.png'
  },
  {
    title: 'Best-in-class\ndesign team',
    description: 'Professional designs handled from start to finish for free',
    image: '/images/catalog/confirmation/best-in-class-design-team.png'
  }
];

// Hide this slide temporarly until routing inside chili piper is working correctly
// const bookMeetingSlide = {
//   image: '/images/catalog/confirmation/get-time-with-a-swag-expert.png',
//   title: 'SWAG CONSULTATION',
//   header: 'Get Time with a\nSwag Expert',
//   subheader: 'Have questions? Don’t be shy!',
//   subheaderList: ['Book a Platform demo', 'Get “secret menu“ swag ideas 😉', 'See how SwagUp can work for you'],
//   link: {
//     text: 'Schedule a Call',
//     to: 'https://swagup.chilipiper.com/router/product-demo-router'
//   }
// }

const blogSlide = {
  image: '/images/catalog/confirmation/explore-the-swagup-blog.png',
  title: 'NEW & TRENDING',
  header: 'Explore the\nSwagUp Blog',
  subheader: 'Get the latest news, product\nupdates, and swag inspiration',
  subheaderList: [
    'Insider news for top swag trends & tips',
    'Learn from the best with swag case studies',
    <>
      <a href={`https://${window.location.host}/landing/blog`}>Subscribe</a> to Swag in the Wild for more
    </>
  ],
  id: 'exploreBlog',
  link: {
    text: 'Explore the Blog',
    to: `https://${window.location.host}/blog`
  }
};

export const guestUserSlides = [
  {
    image: '/images/catalog/confirmation/set-up-your-account.png',
    title: 'DO MORE WITH SWAGUP',
    header: 'Set up your\nAccount',
    subheader: 'You’ve got mail! 📨\nActivate your new SwagUp Account to:',
    subheaderList: [
      'Design, review, and save swag ideas',
      'Easily manage your swag inventory',
      'Create swag shipments to your contacts',
      '...and more!'
    ]
  },
  {
    image: '/images/catalog/confirmation/grab-a-sample-pack.png',
    title: 'SERVING UP FRESH SWAG',
    header: 'Grab a Sample\nPack',
    subheader: 'Get a sense of what it’s like to\nunbox a fully custom swag pack.',
    link: {
      text: 'Request Sample Pack',
      to: '/onboarding/welcome'
    }
  },
  blogSlide
];

export const loggedInUserSlides = [
  {
    image: '/images/catalog/confirmation/add-your-recipient-list.png',
    title: 'PREPARE FOR LAUNCH',
    header: 'Add Your\nRecipient List',
    subheader: 'Upload your directory contacts\nfor seamless swag shipments',
    link: {
      text: 'Upload Recipient List',
      to: '/shipments/contacts'
    },
    id: 'uploadRecipients'
  },
  {
    image: '/images/catalog/confirmation/discover-redeem-pages.png',
    title: 'DO MORE WITH SWAGUP',
    header: 'Discover\nRedeem Pages',
    subheader: 'Simplify swag distribution:',
    subheaderList: [
      'Collect recipient addresses in one place',
      'Let anyone easily redeem swag online',
      'Syncs with existing Dashboard inventory'
    ],
    link: {
      text: 'Explore Redeem Pages',
      to: `https://${window.location.host}/blog/redeem-pages-guide`
    },
    id: 'exploreRedeemPages'
  },
  blogSlide
];

export const loggedInUserSlidesV2 = [
  {
    image: '/images/catalog/confirmation/add-your-recipient-list.png',
    title: 'PREPARE FOR LAUNCH',
    header: 'Add Your\nRecipient List',
    subheader: 'Upload your directory contacts\nfor seamless swag shipments',
    link: {
      text: 'Upload Recipient List',
      to: '/contacts'
    },
    id: 'uploadRecipients'
  },
  {
    image: '/images/catalog/confirmation/discover-redeem-pages.png',
    title: 'DO MORE WITH SWAGUP',
    header: 'Discover\nRedeem Pages',
    subheader: 'Simplify swag distribution:',
    subheaderList: [
      'Collect recipient addresses in one place',
      'Let anyone easily redeem swag online',
      'Syncs with existing Dashboard inventory'
    ],
    link: {
      text: 'Explore Redeem Pages',
      to: `https://${window.location.host}/blog/redeem-pages-guide`
    },
    id: 'exploreRedeemPages'
  },
  blogSlide
];

const useStyles = makeStyles(styles);

const Thanks = () => {
  const classes = useStyles();
  const { state = {} } = useLocation();
  const { userName } = state;
  const { leftBarNavigation } = useFlags();

  const { isAuthenticated } = useAuth();
  const slides = isAuthenticated ? (leftBarNavigation ? loggedInUserSlidesV2 : loggedInUserSlides) : guestUserSlides;

  if (!userName) {
    return <Redirect to="/" />;
  }

  const servicesLink = `https://swagup.com/services`;

  return (
    <main className={classes.main}>
      <Helmet tags={tags.bulkCustomThanks} />
      <CenteredGrid container direction="column" alignItems="center" className={classes.container}>
        <Grid container justifyContent="center" alignItems="center" className={classes.headerContainer}>
          <Img src="/images/shipSwag/truck-150x150.png" alt="shipment-truck" width={150} height={120} />
          <Grid item className={classes.headerTextContainer}>
            <Typography variant="h2" className={classes.h2}>
              Thank you, we’re on it!
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              We’ve sent a confirmation email, be on the lookout for some mockups
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="column" alignItems="center" className={classes.slideshowSection}>
          <Typography variant="h1" className={classes.h1}>
            {userName}, don’t stop here
          </Typography>
          <Slideshow
            slides={slides.map(slide => (
              <Slide key={slide.title} data={slide} />
            ))}
          />
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.servicesContainer}
        >
          <Typography variant="h1" className={classes.h1}>
            Get to know SwagUp
          </Typography>
          <Grid container justifyContent="space-evenly" className={classes.serviceCardsContainer}>
            {cardsContent.map(card => (
              <ServiceCard key={card.title} {...card} />
            ))}
          </Grid>
          <Button
            id="exploreServices"
            component="a"
            href={servicesLink}
            className={classes.servicesBtn}
            target="_blank"
            rel="noopener noreferrer"
          >
            Explore Services
          </Button>
        </Grid>
      </CenteredGrid>
    </main>
  );
};

export default Thanks;
